<template>
    <NuxtLink :to="`/recipes/${recipe.id}`">
        <figure
            class="flex flex-col h-full rounded-2xl border-2 hover:border-primary-200 overflow-hidden bg-white dark:border-none dark:bg-fill-secondary">
            <div class="custom-img-container md:h-[176px]">
                <nuxt-img sizes="xs:100vw sm:50vw md:33vw lg:25vw" width="512" height="400" quality="25"
                    class="animatable-img w-full object-cover" format="webp" :src="recipe.image.url" placeholder
                    :title="recipe.image.title" :alt="recipe.image.alt" />
            </div>
            <div class="text-xl font-bold leading-tight line-clamp-2 overflow-hidden text-wrap px-6 pt-6 text-gray-900 dark:text-gray-100"
                v-html="recipe.name"></div>
            <div class="flex flex-col justify-end flex-1 h-full px-6 pt-3 pb-6">
                <div class="text-sm font-medium text-gray-600 dark:text-gray-400">
                    {{ formatDuration(recipe.totalTime) }}
                </div>
            </div>
        </figure>
    </NuxtLink>
</template>

<script>
export default {
    props: {
        recipe: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
a {
    @apply text-black dark:text-white no-underline;
}
</style>